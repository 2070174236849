import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';
import { addDays, parse, format } from 'date-fns';
import { Formik, Form, Field, FieldProps } from 'formik';
import NextRightIcon from 'public/icons/keyboard_arrow_right.svg';
import styles from '../styles/Home.module.css';
import { api, getStrapiURL } from '@/api/api';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import DateField from '@/components/DateField';
import Flex from '@/components/Flex';
import FormGroup from '@/components/FormGroup';
import SelectField from '@/components/SelectField';
import {
  schema,
  FormFieldsType,
  initialValues,
  timeOptions,
} from '@/forms/itinerary';
import { minPickupDate } from '@/helpers/itinerary';
import { DepotType } from '@/types';
import { useReservationBar } from '@/contexts/reservationBar';
import Modal from 'components/Modal';
import { buildAndPushSearchEvent } from '@/utils/dataLayer/search';
import { brFormatToUsaFormat, kayakFormatToUsaFormat, kayakTimeToNormalTime } from '@/helpers/fdate';
import Image from 'next/image';
import { useSettings } from '@/contexts/settings';
import Link from 'next/link';
import { useLayout } from '@/contexts/layout';
interface HomeProps {
  depots: DepotType[];
  depotsOptions: any;
  serverErrors: any;
}

const modalDefault = {
  isOpen: false,
  title: 'Alerta',
  message: '',
}

export default function Home({ depotsOptions, serverErrors }: HomeProps) {
  const router = useRouter();
  const { setValue, updateBar } = useReservationBar();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(modalDefault);
  const { vantagens } = useSettings();
  const [isReturnStoreEdited, setIsReturnStoreEdited] = useState(false);
  const isSameStore = useMemo(() => router.query?.pickup_store !== undefined && router.query?.return_store !== undefined && router.query?.pickup_store === router.query?.return_store && !isReturnStoreEdited, [router.query, isReturnStoreEdited]);
  const { downloadApp, setDownloadApp } = useLayout();

  useEffect(() => {
    setDownloadApp(true);
    updateBar({
      title: 'Itinerário',
      step: 1,
      progress: 0,
    });
    return () => { setDownloadApp(false) }
  }, []);

  useEffect(() => {
    if (typeof router.query.uiCode !== 'undefined') {
      const uiCode = router.query.uiCode;
      router.replace('/', undefined, { shallow: true });
      switch (uiCode) {
        case '2724':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Não é possível drop off entre essas duas lojas'
          });
          break;
        case '1655':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Esta loja não está aberta no horário selecionado para Retirada. Por gentileza, escolha outro horário.'
          });
          break;
        case '1656':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Esta loja não está aberta no horário selecionado para Devolução. Por gentileza, escolha outro horário.'
          });
          break;
        case '1657':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Esta loja não está aberta no horário selecionado para Retirada. Por gentileza, escolha outro horário.'
          });
          break;
        case '1658':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Esta loja não está aberta no horário selecionado para Devolução. Por gentileza, escolha outro horário.'
          });
          break;
        case 'DT30':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'O horário escolhido está muito próximo. Escolha um horário com no mínimo 30 minutos de antecedência'
          });
          break;
        default:
          setModal({
            isOpen: true,
            title: 'Erro',
            message: 'Houve um erro, favor tentar novamente'
          });
      }
    }
  }, [router.query]);

  const onSubmit = (values: FormFieldsType) => {
    if (values.return_store === 'SAME') {
      values.return_store = values.pickup_store;
    }
    const depotOption = depotsOptions.find(
      (depot: any) => depot.value === values.pickup_store,
    );
    let query: any = {
      pickup_store: values.pickup_store,
      pickup_date: values.pickup_date.toISOString().split('T')[0],
      pickup_time: values.pickup_time,
      return_store: values.return_store,
      return_date: values.return_date.toISOString().split('T')[0],
      return_time: values.return_time,
      driver: router.query.driver || 21,
      coupon: values.promo_code || '',
    };
    if (router.query.cdp_id) {
      query = {
        ...query,
        cdp_id: router.query.cdp_id,
      }
    }
    setIsLoading(true);
    setValue(1, depotOption.label);
    router.push({
      pathname: '/veiculos',
      query: {
        ...query,
        cdp_id: query.cdp_id || "FOCPRE"
      }
    })
      .then(() => setIsLoading(false));
  };

  const getInitialValue = (key: string, initial: any): any => {
    if (typeof router.query[key] === 'string') {
      const value = decodeURIComponent(router.query[key] as string);
      if (key === 'pickup_date' || key === 'return_date') {
        const formats = ['dd/MM/yyyy', 'dd/MM/yy'];
        let parseFormat = 'yyyy-MM-dd';
        if (formats.includes(router.query.date_format as string)) {
          parseFormat = router.query.date_format as string;
        }
        const parsed = parse(value, parseFormat, new Date());
        parsed.setHours(12);
        parsed.setMinutes(0);
        return parsed.toString() === 'Invalid Date' ? initial : parsed;
      }
      if (key === 'pickup_time' || key === 'return_time') {
        const [hour, time] = (router.query[key] as string)?.split(':');
        const auxDate = new Date();
        auxDate.setHours(hour as any);
        auxDate.setMinutes(time as any);
        return format(auxDate, 'HH:mm');
      }
      return value;
    }
    return initial;
  }

  const closeModal = () => {
    setModal(modalDefault);
  }

  const applyCoupon = (coupon: string) => {
    router.push({
      pathname: '/',
      query: {
        ...router.query,
        coupon: coupon,
      }
    });
  }

  function getMobileAppLink(): string {
    if (typeof navigator !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || '';
      if (/android/i.test(userAgent)) {
        return 'https://play.google.com/store/apps/details?id=foco.APP';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'https://apps.apple.com/br/app/foco-aluguel-de-carros/id1622202895';
      }
    }
    return 'https://play.google.com/store/apps/details?id=foco.APP';
  }

  return (
    <div className={styles.main__container}>
      {downloadApp &&
        <div className={styles.download__app}>
          <p className={styles.download__app__text}>Baixe o <strong>aplicativo da Foco</strong> e tenha mais praticidade</p>
          <Link href={getMobileAppLink()} legacyBehavior>
            <a
              className={styles.download__app__link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar agora
            </a>
          </Link>
        </div>
      }
      <h2 className={styles.main__title}>Sua Viagem</h2>
      <hr className={styles.hr} />
      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        onClose={() => closeModal()}
        onConfirm={() => closeModal()}
      >
        {modal.message}
      </Modal>
      <div className={styles.wrapp__container}>
        <div className={styles.form__wrap}>
          <Formik
            initialValues={{
              pickup_store: getInitialValue('pickup_store', ''),
              pickup_date: getInitialValue('pickup_date', addDays(new Date(), 1)),
              pickup_time: getInitialValue('pickup_time', '12:00'),
              return_store: getInitialValue('return_store', 'SAME'),
              return_date: getInitialValue('return_date', addDays(new Date(), 2)),
              return_time: getInitialValue('return_time', '12:00'),
              promo_code: getInitialValue('coupon', ""),
            }}
            onSubmit={onSubmit}
            validationSchema={schema}
          >
            {({ errors, touched, setFieldValue, values, setTouched }) => (
              <Form>
                <div>
                  <div className={styles.form__inputs}>
                    {/* PICKUP */}
                    <Flex direction="column" flex={1}>
                      <FormGroup
                        label="Local de retirada"
                        htmlFor="pickup_store"
                        errors={errors?.pickup_store}
                        touched={touched?.pickup_store}
                      >
                        {/* PICKUP LOCATION */}
                        <Field
                          className={errors?.pickup_store && touched?.pickup_store ? 'field__has_error' : ''}
                          name="pickup_store"
                          id="pickup_store"
                          component={SelectField}
                          options={depotsOptions}
                          placeholder="Selecionar"
                          afterChange={(option: any) => {
                            if (isSameStore) {
                              setFieldValue('return_store', option.value);
                            }
                          }}
                        />
                      </FormGroup>
                      <Flex direction="row" gap={'1rem'}>
                        {/* PICKUP DATE */}
                        <FormGroup
                          label="Data da Retirada"
                          htmlFor="pickup_date"
                          errors={errors?.pickup_date as string}
                          touched={true}
                        >
                          <Field
                            className={errors?.pickup_date ? 'field__has_error' : ''}
                            name="pickup_date"
                            id="pickup_date"
                            component={DateField}
                            minDate={minPickupDate}
                            onChange={(val: Date) => {
                              if (!touched.return_date || val > values.return_date) {
                                setFieldValue('return_date', addDays(val, 1));
                              }
                            }}
                            withPortal
                          />
                        </FormGroup>
                        {/* PICKUP TIME */}
                        <FormGroup
                          label="Hora da Retirada"
                          htmlFor="pickup_time"
                          errors={errors?.pickup_time}
                          touched={true}
                        >
                          <Field
                            className={errors?.pickup_time && touched?.pickup_time ? 'field__has_error' : ''}
                            name="pickup_time"
                            id="pickup_time"
                            component={SelectField}
                            options={timeOptions}
                          />
                        </FormGroup>
                      </Flex>
                      {/* RETURN */}
                      <Flex direction="column" flex={1}>
                        {/* RETURN LOCATION */}
                        <FormGroup
                          label="Local de Devolução"
                          htmlFor="return_store"
                          errors={errors?.return_store}
                          touched={touched?.return_store}
                        >
                          <Field
                            className={errors?.return_store && touched?.return_store ? 'field__has_error' : ''}
                            name="return_store"
                            id="return_store"
                            component={SelectField}
                            options={[
                              {
                                label: 'Mesmo local de retirada',
                                value: 'SAME',
                              },
                              ...depotsOptions,
                            ]}
                            afterChange={() => {
                              setIsReturnStoreEdited(true);
                            }}
                          />
                        </FormGroup>
                        <Flex direction="row" gap={'1rem'}>
                          {/* RETURN DATE */}
                          <FormGroup
                            label="Data da Devolução"
                            htmlFor="return_date"
                            errors={errors?.return_date as string}
                            touched={true}
                          >
                            <Field name="return_date" id="return_date">
                              {({ field, form, meta }: FieldProps) => (
                                <DateField
                                  form={form}
                                  meta={meta}
                                  minDate={values.pickup_date}
                                  withPortal
                                  field={field}
                                  type="date"
                                  onBlur={() => setTouched({ return_date: true })}
                                />
                              )}
                            </Field>
                          </FormGroup>
                          {/* RETURN TIME */}
                          <FormGroup
                            label="Hora da Devolução"
                            htmlFor="return_time"
                            errors={errors?.return_time}
                            touched={true}
                          >
                            <Field
                              className={errors?.return_time && touched?.return_time ? 'field__has_error' : ''}
                              name="return_time"
                              id="return_time"
                              component={SelectField}
                              options={timeOptions}
                            />
                          </FormGroup>
                        </Flex>
                        {/* Cupom*/}
                        <FormGroup
                          label="Cupom de Desconto"
                          htmlFor="promo_code"
                          errors={errors?.promo_code}
                          touched={true}
                        >
                          <div className={styles.cupom__wrapp}>
                            <Field
                              className={`cupom__field text__field ${errors?.promo_code && touched?.promo_code ? 'field__has_error' : ''}`}
                              name="promo_code"
                              id="promo_code"
                              maxLength={6}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const { value } = event.target;
                                setFieldValue('promo_code', value.toUpperCase());
                                if (value.length === 6) {
                                }
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Flex>
                    </Flex>
                  </div>
                  <hr className={styles.hr} />
                  <FormGroup className={styles.submit_wrap} isBtnSubmit>
                    <Button
                      id="submitBtn"
                      className={styles.next__button}
                      type="submit"
                      hasRightIcon
                      isLoading={isLoading}
                    >
                      {isLoading ? (
                        'Carregando ...'
                      ) : (
                        <>
                          Continuar
                        </>
                      )}
                    </Button>
                  </FormGroup>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className={styles.image__container}>
          {vantagens.image_suaViagem_desktop &&
            <Image
              src={getStrapiURL(vantagens.image_suaViagem_desktop.url)}
              alt='foto'
              width={340}
              height={500}
              className={styles.home__image}
            />
          }
        </div>
        <div className={styles.image__container__mob}>
          {vantagens.image_suaViagem_mobile &&
            <Image
              src={getStrapiURL(vantagens.image_suaViagem_mobile.url)}
              alt='foto'
              width={355}
              height={146}
              className={styles.home__image}
            />
          }
        </div>
      </div>
    </div>
  );
}

export interface DepotOptionType {
  label: string;
  value: string;
}

export async function getServerSideProps({ query }: any) {
  const depotsOptions: DepotOptionType[] = [];
  const serverErrors: any[] = [];
  if (query.origin === 'SKYSCANNER' && typeof query.deeplink === 'undefined') {
    const { pickup_location, pickup_date, return_location, return_date, ...rest } = query;
    const params = new URLSearchParams({
      pickup_store: pickup_location,
      pickup_date: brFormatToUsaFormat(pickup_date),
      return_store: return_location,
      return_date: brFormatToUsaFormat(return_date),
      ...rest,
      driver: query.driver || 21,
      coupon: query.coupon || '',
      deeplink: 1,
    });
    return {
      redirect: {
        permanent: false,
        destination: '/veiculos?' + params.toString(),
      }
    }
  } else if (query.utm_source === 'kayak' && typeof query.deeplink === 'undefined') {
    const { PICKUP_LOC, PICKUP_DATE, PICKUP_MONTH_YEAR, PICKUP_TIME, DROPOFF_LOC, DROPOFF_DATE, DROPOFF_MONTH_YEAR, DROPOFF_TIME, ...rest } = query;
    const params = new URLSearchParams({
      pickup_store: PICKUP_LOC,
      pickup_date: kayakFormatToUsaFormat(PICKUP_DATE, PICKUP_MONTH_YEAR),
      pickup_time: kayakTimeToNormalTime(PICKUP_TIME),
      return_store: DROPOFF_LOC,
      return_date: kayakFormatToUsaFormat(DROPOFF_DATE, DROPOFF_MONTH_YEAR),
      return_time: kayakTimeToNormalTime(DROPOFF_TIME),
      cdp_id: 'KAYAK',
      ...rest,
      driver: query.driver || 21,
      coupon: query.coupon || '',
      origin: 'KAYAK',
      deeplink: 1,
    });
    return {
      redirect: {
        permanent: false,
        destination: '/veiculos?' + params.toString(),
      }
    }
  }
  try {
    const { data } = await api.get('/depots');
    data.sort((a: DepotType, b: DepotType) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    }).map((depot: DepotType) => {
      depotsOptions.push({
        label: depot.title,
        value: depot.coral_code as string,
      });
    });
    return {
      props: {
        depots: data,
        depotsOptions,
        serverErrors,
      },
    };
  } catch (err) {
    return {
      props: {
        depots: [],
        depotsOptions,
        serverErrors: [
          {
            code: 0,
            message:
              'Houve um erro no servidor, não conseguimos carregar as lojas',
          },
        ],
      },
    };
  }
}
